.title-echeancier {
  text-transform: none;
  opacity: 0.8;
  font-size: 1.1rem;
}
.title-reglement {
  text-transform: none;
  opacity: 0.8;
  font-size: 0.9rem;
}
.processColor {
  color: #e48360;
}
.bloc-item {
  position: relative;
}
.toggler-collapse {
  border: 1px solid #e48360;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 7px;
  cursor: pointer;
}
.arrow {
  border-top: 1px solid #e48360;
  border-left: 1px solid #e48360;
  transform: rotate(45deg);
  width: 6px;
  height: 6px;
  margin-left: 6px;
  margin-top: 7px;
}
.display-none {
  display: none;
}
.rotate {
  transform: rotate(180deg);
}
ul {
  list-style: none;
  padding: 0;
}
ul li {
  margin-bottom: 10px;
}
.button-create-sujet-commit {
  height: 35px;
}
.cell-background-white {
  background-color: #fff;
}
/*# sourceMappingURL=src/pages/process/SuiviDesIc/echeances.css.map */